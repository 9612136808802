<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Contrato</h3>
        </div>
        <div class="card-body">
          <div class="row d-flex align-items-end">
            <div class="form-group col-md-3">
              <label>Tipo</label>
              <select v-model="pesquisa.tipo" class="form-control">
                <option value="nome">Nome Contrato</option>
                <option value="tipo_produto">Tipo Contrato</option>
              </select>
            </div>
            <div class="form-group col-md-8">
              <label for="text">Campo</label>
              <input
                v-model="pesquisa.texto"
                type="text"
                class="form-control"
                id="text"
                placeholder
              />
            </div>
            <!-- <div class="form-group">
          <button class="btn btn-success" @click="pesquisar()">
            <i class="fa fa-search search-icon m-0 p-0"></i>
          </button>
        </div> -->
          </div>
          <b-table
            :fields="['nome', 'valor', 'contrato','servicos', 'acoes']"
            :items="lista_contrato_servico"
            :per-page="perPage"
            :current-page="currentPage"
            id="produto-table"
            class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
            "
            show-empty
            empty-text="Nenhum registro encontrado!"
          >
            <template #head(acoes)><span></span></template>
            <template #cell(contrato)="{ item }">{{ item.contrato.nome }}</template>
            <template #cell(servicos)="{ item }">
              <span class="text-muted font-weight-bold d-block" v-for="(lista,index) in item.servicos"  :key="index">
              {{ lista.nome }}
              </span></template>


            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <button
                  v-show="lista_permissoes_filial.u_Produto"
                  @click="atualizar(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Editar registro"
                >
                  <i class="far fa-edit text-primary"></i>
                </button>

                <button
                  v-show="lista_permissoes_filial.lock_Produto"
                  @click="confirm(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Bloquear/Desbloquear registro"
                >
                  <i class="fas fa-unlock-alt text-danger"></i>
                </button>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista_contrato_servico.length"
            :per-page="perPage"
            aria-controls="produto-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
export default {
  data() {
    return {
      pesquisa: {
        tipo: "nome",
        texto: "",
      },
      currentPage: 1,
      perPage: 10,
    };
  },
  mixins: [fireAlert],
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Contrato Serviços" }]);

  },
  created() {
    this.listar_produtos();
  },
  computed: {
    lista_contrato_servico() {
      return this.$store.state.contratoServico.lista_contrato_servico.filter((item) => {
        if (!this.pesquisa.texto) return true;
        return (
          String(item[this.pesquisa.tipo])
            .toLowerCase()
            .indexOf(this.pesquisa.texto.toLowerCase()) !== -1
        );
      });
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.contratoServico.mensagem_alert;
    },
  },
  methods: {
    async listar_produtos() {
        await this.$store.dispatch("contratoServico/listar_contrato_servico").finally(() => {
            this.$store.dispatch("configEmpresa/atualizar", "");
      });
    },
    atualizar(value) {
      this.$router.push({ name: "createContratoServico" });
      this.$store.dispatch("configEmpresa/atualizar", value);
      // localStorage.setItem('produto',JSON.stringify(value));
      // this.$store.dispatch("contratoServico/listar_produto", value.id);
    },
    async pesquisar() {
        await this.$store.dispatch("contratoServico/pesquisar_produto", this.pesquisa);
      },
    async confirm(value) {
      await this.$confirm({
        title: "Tem Certeza?",
        message: `Esta ação exclui este contrato servico no sistema?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("contratoServico/delete_contrato_servico", value);
      this.fireAlert({
        ...this.mensagem_alert,
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>